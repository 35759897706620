import GTP from "../../mappings/gtp.txt";
import GGD_P5 from "../../mappings/ggd_p5.txt";
import { GGD_P5_ALIASES, GTP_ALIASES, type Alias } from "./aliases";
import type { PreferredNoteMapping } from "./preferred-note-mappings";

class NameToNote {
  private map: Map<string, Set<number>> = new Map();

  constructor(
    private readonly preferredNoteMapping: PreferredNoteMapping = {},
  ) { }

  add(name: string, note: number): void {
    const notes = this.map.get(name) ?? new Set();
    notes.add(note);
    this.map.set(name, notes);
  }

  get(name: string): Set<number> | null {
    return this.map.get(name) ?? null;
  }

  getOne(name: string): number | null {
    const notes = this.get(name);
    if (!notes) {
      return null;
    }

    const preferred = this.preferredNoteMapping[name];
    if (preferred && notes.has(preferred)) {
      return preferred;
    }

    return notes.values().next().value;
  }
}

export class ReaperDrumMap {
  noteToName: Map<number, string> = new Map();
  nameToNote: NameToNote;

  constructor(
    lines: [number, string][],
    aliases?: Alias[],
    preferredNoteMappings?: PreferredNoteMapping,
  ) {
    this.nameToNote = new NameToNote(preferredNoteMappings);

    for (const [note, name] of lines) {
      this.noteToName.set(note, name);
      this.nameToNote.add(name, note);

      const matchingAliases =
        aliases?.filter((alias) => alias.altName === name) ?? [];
      for (const alias of matchingAliases) {
        this.nameToNote.add(alias.name, note);
      }
    }
  }

  static fromString(data: string, aliases?: Alias[]): ReaperDrumMap {
    const lines = data.split("\n");
    const input = lines.reduce<[number, string][]>((acc, line) => {
      const trimmed = line.trim();

      if (trimmed.startsWith("#")) {
        return acc;
      }

      if (trimmed === "") {
        return acc;
      }

      const [note, ...name] = trimmed.split(" ");
      if (!note) {
        return acc;
      }

      const noteValue = parseInt(note, 10);
      const nameValue = name.join(" ");
      return [...acc, [noteValue, nameValue]];
    }, []);

    return new ReaperDrumMap(input, aliases);
  }

  getName(note: number): string | null {
    return this.noteToName.get(note) ?? null;
  }

  getNote(name: string): number | null {
    return this.nameToNote.getOne(name);
  }
}

export type DrumMap = "GTP" | "GGD_P5";

export const DrumMap = {
  GTP: ReaperDrumMap.fromString(GTP, GTP_ALIASES),
  GGD_P5: ReaperDrumMap.fromString(GGD_P5, GGD_P5_ALIASES),
};
