import { MidiTrack, type MidiFile } from "../models/midi-file";
import { DrumMap, ReaperDrumMap } from "../models/drum-map";

export class RemapDrumsUseCase {
  // Only GTP -> GGD_P5 is supported for now
  invoke({
    midiFile,
    from,
    to,
  }: {
    midiFile: MidiFile;
    from: DrumMap;
    to: DrumMap;
  }): MidiFile {
    if (from === to) {
      return midiFile;
    }

    const fromMap = from === "GTP" ? DrumMap.GTP : DrumMap.GGD_P5;
    const toMap = to === "GTP" ? DrumMap.GTP : DrumMap.GGD_P5;

    return this.remapFile({ midiFile, from: fromMap, to: toMap });
  }

  remapFile({
    midiFile,
    from,
    to,
  }: {
    midiFile: MidiFile;
    from: ReaperDrumMap;
    to: ReaperDrumMap;
  }): MidiFile {
    const drumTracks = midiFile.tracks.filter(
      (track) =>
        track.name === "Percussion" ||
        track.name === "Drums" ||
        track.channel === 9,
    );

    for (const track of drumTracks) {
      this.remapTrack({ track, from, to });
    }

    return midiFile;
  }

  remapTrack({
    track,
    from,
    to,
  }: {
    track: MidiTrack;
    from: ReaperDrumMap;
    to: ReaperDrumMap;
  }): MidiTrack {
    let index = 0;
    for (const event of track.events) {
      index++;
      if (event.type === "noteOn" || event.type === "noteOff") {
        const note = event.noteNumber;
        const name = from.getName(note);
        if (!name) {
          console.warn(
            `[${track.name}]: No name found for note "${note}" at position ${index}/${track.events.length}`,
            event,
          );
          continue;
        }

        const newNote = to.getNote(name);
        if (!newNote) {
          console.warn(
            `[${track.name}]: No note found for name "${name}" at position ${index}/${track.events.length}`,
            event,
          );
          continue;
        }

        event.noteNumber = newNote;
      }
    }
    return track;
  }
}
