import { LazyGetter } from "lazy-get-decorator";
import {
  type MidiEvent,
  type MidiTrackNameEvent,
  type MidiProgramChangeEvent,
  type MidiData,
  parseMidi,
  writeMidi,
} from "midi-file";

const MIME_TYPE = "data:audio/midi";
const BASE64_PREFIX = `${MIME_TYPE};base64,`;

export class MidiFile {
  tracks: MidiTrack[];

  constructor(public data: MidiData) {
    this.tracks = data.tracks.map(
      (track, index) => new MidiTrack(index, track),
    );
  }

  static fromBuffer(buffer: Buffer): MidiFile {
    const parsed = parseMidi(buffer);
    return new MidiFile(parsed);
  }

  static fromBase64(base64: string): MidiFile {
    const buffer = Buffer.from(base64.replace(BASE64_PREFIX, ""), "base64");
    return MidiFile.fromBuffer(buffer);
  }

  toBuffer(): Buffer {
    const output = writeMidi(this.data);
    return Buffer.from(output);
  }

  toBase64(): string {
    return this.toBuffer().toString("base64");
  }

  removeTrack(index: number): MidiFile {
    if (index < 0 || index >= this.data.tracks.length) {
      throw new Error("Invalid track index");
    }

    this.data.tracks.splice(index, 1);
    this.data.header.numTracks = this.data.tracks.length;
    this.tracks.splice(index, 1);
    return this;
  }
}

export class MidiTrack {
  constructor(
    public readonly index: number,
    public events: MidiEvent[],
  ) {}

  @LazyGetter()
  get name() {
    const trackNameEvent = this.events.find(
      (e): e is MidiTrackNameEvent => e.type === "trackName",
    );
    if (!trackNameEvent) {
      console.warn("No track name event found in track, using default name");
      return `Track ${this.index + 1}`;
    }
    return trackNameEvent.text;
  }

  @LazyGetter()
  get channel() {
    const programChangeEvent = this.events.find(
      (e): e is MidiProgramChangeEvent => e.type === "programChange",
    );
    if (!programChangeEvent) {
      console.warn("No program change event found in track, using channel 0");
      return 0;
    }
    return programChangeEvent.channel;
  }
}
