import { z } from "zod";
import { Elm } from "./Main.elm";
import { MidiFile } from "@midi-toolkit/core";

const app = Elm.Main.init({
  node: document.getElementById("root"),
  flags: {},
});

const MutationSchema = z.union([
  z.object({
    type: z.literal("RemoveTrack"),
    index: z.number().int().nonnegative(),
  }),
  z.object({
    type: z.literal("RemapDrums"),
    index: z.number().int().nonnegative(),
    from: z.string(),
    to: z.string(),
  }),
]);
const MutateMidiSchema = MutationSchema.and(z.object({ data: z.string() }));
const RequestMidiMetadataInputSchema = z.string();

const sendMidiMetadata = (midiFile: MidiFile) => {
  app.ports.midiMetadataRecieved.send({
    data: midiFile.toBase64(),
    tracks: midiFile.tracks.map((track) => ({
      name: track.name,
      channel: track.channel,
    })),
  });
};

app.ports.requestMidiMetadata.subscribe((input: unknown) => {
  const data = RequestMidiMetadataInputSchema.parse(input);
  const midiFile = MidiFile.fromBase64(data);
  sendMidiMetadata(midiFile);
});

app.ports.removeTrack.subscribe((input: unknown) => {
  const { type, data, index } = MutateMidiSchema.parse(input);
  const midiFile = MidiFile.fromBase64(data);

  switch (type) {
    case "RemapDrums":
      // TODO
      break;

    case "RemoveTrack":
      sendMidiMetadata(midiFile.removeTrack(index));
      break;
  }
});
