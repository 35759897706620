port module Main exposing (Model, Msg(..), dropDecoder, hijack, hijackOn, init, main, subscriptions, update, view)

import Base64
import Browser
import Element exposing (Element)
import Element.Border
import Element.Input
import File exposing (File)
import File.Download as Download
import File.Select as Select
import Html exposing (Html)
import Html.Events
import Json.Decode as D
import Json.Decode.Pipeline as Pipeline
import Json.Encode as E
import Task



---- OUTGOING PORTS ----


port requestMidiMetadata : E.Value -> Cmd msg


port removeTrack : E.Value -> Cmd msg



---- INCOMING PORTS ----


port midiMetadataRecieved : (D.Value -> msg) -> Sub msg


decodeRecievedMidiMetadata : D.Decoder MidiFile
decodeRecievedMidiMetadata =
    D.succeed MidiFile
        |> Pipeline.required "data" D.string
        |> Pipeline.required "tracks"
            (D.list
                (D.succeed MidiTrack
                    |> Pipeline.required "name" D.string
                    |> Pipeline.required "channel" D.int
                )
            )



-- MAIN


main : Program () Model Msg
main =
    Browser.element
        { view = view
        , init = init
        , update = update
        , subscriptions = subscriptions
        }



-- MODEL


type alias MidiTrack =
    { name : String, channel : Int }


type alias MidiFile =
    { base64 : String, tracks : List MidiTrack }


type Stage
    = FileSelect { hover : Bool, error : Maybe String }
    | MidiParsing { filename : String, data : String }
    | MidiParsed { filename : String, midi : MidiFile }


type alias Model =
    Stage


init : () -> ( Model, Cmd Msg )
init _ =
    ( FileSelect { hover = False, error = Nothing }, Cmd.none )



-- UPDATE


type alias RemapDrumsOptions =
    { from : String, to : String }


type MidiMutation
    = RemoveTrack Int
    | RemapDrums Int RemapDrumsOptions


type Msg
    = Pick
    | DragEnter
    | DragLeave
    | GotFile File
    | GotFiles File (List File)
    | MidiMetadataRequested String
    | MidiMetadataReceived D.Value
    | MutateMidi MidiMutation
    | Download


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case ( model, msg ) of
        ( FileSelect _, Pick ) ->
            ( model
            , Select.file [ "audio/midi" ] GotFile
            )

        ( FileSelect submodel, DragEnter ) ->
            ( FileSelect { submodel | hover = True }
            , Cmd.none
            )

        ( FileSelect submodel, DragLeave ) ->
            ( FileSelect { submodel | hover = False }
            , Cmd.none
            )

        ( FileSelect _, GotFile file ) ->
            ( MidiParsing { filename = File.name file, data = "" }
            , Task.perform MidiMetadataRequested (File.toUrl file)
            )

        ( FileSelect _, GotFiles file _ ) ->
            ( MidiParsing { filename = File.name file, data = "" }
            , Task.perform MidiMetadataRequested (File.toUrl file)
            )

        ( FileSelect _, _ ) ->
            ( model
            , Cmd.none
            )

        ( MidiParsing submodel, MidiMetadataRequested data ) ->
            ( MidiParsing { submodel | data = data }
            , requestMidiMetadata (E.string data)
            )

        ( MidiParsing submodel, MidiMetadataReceived data ) ->
            case D.decodeValue decodeRecievedMidiMetadata data of
                Ok file ->
                    ( MidiParsed { filename = submodel.filename, midi = file }, Cmd.none )

                Err error ->
                    ( FileSelect { hover = False, error = Just (D.errorToString error) }, Cmd.none )

        ( MidiParsing _, _ ) ->
            ( model
            , Cmd.none
            )

        ( MidiParsed submodel, MidiMetadataReceived data ) ->
            case D.decodeValue decodeRecievedMidiMetadata data of
                Ok file ->
                    ( MidiParsed { filename = submodel.filename, midi = file }, Cmd.none )

                Err _ ->
                    ( MidiParsed submodel, Cmd.none )

        ( MidiParsed submodel, MutateMidi mutation ) ->
            let
                encodedMutation =
                    case mutation of
                        RemoveTrack index ->
                            [ ( "type", E.string "RemoveTrack" ), ( "index", E.int index ) ]

                        RemapDrums index options ->
                            [ ( "type", E.string "RemapDrums" )
                            , ( "index", E.int index )
                            , ( "from", E.string options.from )
                            , ( "to", E.string options.to )
                            ]
            in
            ( MidiParsed submodel
            , removeTrack (E.object <| ( "data", E.string submodel.midi.base64 ) :: encodedMutation)
            )

        ( MidiParsed submodel, Download ) ->
            ( MidiParsed submodel
            , saveFile submodel.midi.base64
            )

        ( MidiParsed _, _ ) ->
            ( model
            , Cmd.none
            )



-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions _ =
    Sub.batch
        [ midiMetadataRecieved MidiMetadataReceived
        ]



-- VIEW


view : Model -> Html Msg
view model =
    Element.layout
        [ Element.width Element.fill
        , Element.height Element.fill
        ]
    <|
        case model of
            FileSelect { hover } ->
                viewFileSelect { hover = hover }

            MidiParsing submodel ->
                viewMidiParsing submodel

            MidiParsed submodel ->
                viewMidiParsed submodel


viewFileSelect : { hover : Bool } -> Element Msg
viewFileSelect model =
    Element.row
        [ hijackOn "dragenter" (D.succeed DragEnter)
        , hijackOn "dragover" (D.succeed DragEnter)
        , hijackOn "dragleave" (D.succeed DragLeave)
        , hijackOn "drop" dropDecoder
        , Element.centerX
        , Element.centerY
        , Element.width (Element.px 400)
        , Element.height (Element.px 200)
        , Element.Border.rounded 20
        , Element.Border.width 6
        , Element.Border.dashed
        , Element.Border.color
            (if model.hover then
                Element.rgb255 0x1E 0x78 0xC8

             else
                Element.rgb255 0xA3 0xAB 0xBD
            )
        ]
        [ Element.Input.button
            [ Element.Border.rounded 6
            , Element.Border.width 2
            , Element.Border.color (Element.rgb255 0xA3 0xAB 0xBD)
            , Element.padding 10
            , Element.centerX
            , Element.height (Element.px 40)
            ]
            { onPress = Just Pick, label = Element.text "Upload MIDI" }
        ]


viewMidiParsing : { filename : String, data : String } -> Element Msg
viewMidiParsing model =
    Element.row [ Element.centerX, Element.centerY ]
        [ Element.text ("Parsing " ++ model.filename)
        ]


viewMidiParsed : { filename : String, midi : MidiFile } -> Element Msg
viewMidiParsed { filename, midi } =
    Element.column [ Element.centerX, Element.height Element.fill, Element.padding 20 ]
        [ Element.text filename
        , viewMidiTracks midi.tracks
        , Element.Input.button
            [ Element.Border.rounded 6
            , Element.Border.width 2
            , Element.Border.color (Element.rgb255 0xA3 0xAB 0xBD)
            , Element.padding 10
            , Element.centerX
            , Element.height (Element.px 40)
            ]
            { onPress = Just Download, label = Element.text "Download" }
        ]


viewMidiTracks : List MidiTrack -> Element Msg
viewMidiTracks tracks =
    Element.column [ Element.paddingXY 0 20, Element.spacing 10 ] (List.indexedMap viewMidiTrack tracks)


viewMidiTrack : Int -> MidiTrack -> Element Msg
viewMidiTrack index track =
    Element.row []
        [ Element.text track.name
        , Element.text <| "[" ++ String.fromInt track.channel ++ "]"
        , Element.Input.button
            [ Element.Border.rounded 6
            , Element.Border.width 2
            , Element.Border.color (Element.rgb255 0xA3 0xAB 0xBD)
            , Element.padding 10
            , Element.centerX
            , Element.height (Element.px 40)
            ]
            { onPress = Just (MutateMidi <| RemoveTrack index), label = Element.text "Remove" }
        ]


dropDecoder : D.Decoder Msg
dropDecoder =
    D.at [ "dataTransfer", "files" ] (D.oneOrMore GotFiles File.decoder)


hijackOn : String -> D.Decoder msg -> Element.Attribute msg
hijackOn event decoder =
    Element.htmlAttribute
        (Html.Events.preventDefaultOn event (D.map hijack decoder))


hijack : msg -> ( msg, Bool )
hijack msg =
    ( msg, True )


saveFile : String -> Cmd msg
saveFile base64 =
    case Base64.toBytes base64 of
        Nothing ->
            Cmd.none

        Just bytes ->
            Download.bytes "file.mid" "audio/midi" bytes
