export type Alias = { name: string; altName: string };

export const GTP_ALIASES: Alias[] = [
  { name: "Crash medium (hit 2)", altName: "Crash medium (hit)" },
  { name: "Hi-Hat (open 2)", altName: "Hi-Hat (open)" },
  { name: "Hi-Hat (open 3)", altName: "Hi-Hat (open)" },
  { name: "Hi-Hat (half 2)", altName: "Hi-Hat (half)" },
  { name: "Hi-Hat (half 3)", altName: "Hi-Hat (half)" },
  { name: "Hi-Hat (closed 2)", altName: "Hi-Hat (closed)" },
  { name: "Crash high (hit 2)", altName: "Crash high (hit)" },
]

export const GGD_P5_ALIASES: Alias[] = [
  { name: "Mid Tom (hit)", altName: "Low Tom (hit)" },
  { name: "Tambourine (hit)", altName: "Splash (hit)" },
  { name: "Low Floor Tom (hit)", altName: "Low Tom (hit)" },
  { name: "High Floor Tom (hit)", altName: "High Tom (hit)" },
];
